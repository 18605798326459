import React, { Component } from "react"
import StartRequestForm from "../../components/dashboard/startRequestForm"
import { Steps } from "intro.js-react"
import "../../content/css/onboarding.css"
import "intro.js/introjs.css";
import IntroWrapper from '../../components/client-only-wrapper';

export default class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: ".step-1",
          title: "Video Request",
          intro:
            "This is the <b>Video Request</b> form. Use this form for event or product promotion",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".step-3",
          title: "Select a Date",
          intro:
            "When selecting a date - it has to be 1 month or more from when you require the promotion to run",
          position: "right",
          tooltipClass: "intro-title",
        },
        {
          element: ".step-4",
          title: "NOTE:",
          intro:
            "You can select a faster turn around but then you will need to give a reason as to why you need it sooner. This will be at the discretion of head office.",
          position: "right",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }

  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>
        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>
        <StartRequestForm
          department="video"
          introRequestContainer="step-1"
          introRequestContainerStepTwo="step-2"
          introDatePicker="step-3"
          introDatePickerNote="step-4"
        />
      </div>
    )
  }
}
